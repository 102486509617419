// noinspection ES6UnusedImports
/** @jsx jsx */
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'


type SwatchProps = {
  color: string
  name: string
  hex: string
}

const Swatch = ({color, name, hex}: SwatchProps) => (
  <Box style={{
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '150px',
    flexShrink: 0,
  }}>
    <Box sx={{
      width: '150px',
      height: '150px',
      backgroundColor: color,
    }}/>
    <Box>{name}</Box>
    <Box>{hex}</Box>
  </Box>
)

const StyleGuide = () => {
  return (
    <div sx={{
      padding: '2rem',
    }}>
      <Themed.h1 style={{textAlign: 'center'}}>ATX Defense Style Guide</Themed.h1>

      <Themed.h1 sx={{mt: '4rem'}}>Colors</Themed.h1>
      <hr/>

      <Box sx={{
        display: 'flex',
        gap: '2rem',
        flexWrap: 'wrap',
      }}>
        <Swatch name="ATX Dark Gray" hex="#403c3d" color="atxDarkGray"/>
        <Swatch name="ATX Medium Gray" hex="#58595b" color="atxMediumGray"/>
        <Swatch name="ATX Light Gray" hex="#b2b1b1" color="atxLightGray"/>
        <Swatch name="ATX Orange" hex="#d15e14" color="atxOrange"/>
        <Swatch name="ATX Blue" hex="#316094" color="atxBlue"/>
        <Swatch name="ATX Yellow" hex="#ffb71b" color="atxYellow"/>
      </Box>

      <Themed.h1 sx={{mt: '4rem'}}>Headings</Themed.h1>
      <hr/>

      <Themed.h1>Heading 1</Themed.h1>
      <Themed.h2>Heading 2</Themed.h2>
      <Themed.h3>Heading 3</Themed.h3>
      <Themed.h4>Heading 4</Themed.h4>
      <div>Body Text</div>
    </div>
  )
}

export default StyleGuide
